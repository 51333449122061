@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');

.wrapper {
  font-family: 'Cutive Mono', monospace;
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper__img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 1024px;
  z-index: -10;
}

@media (max-width: 1024px) { 
  .wrapper__img {
    left: 50%;
    margin-left: -512px;   /* 50% */
  }
}
