/* Note-- had to use ID's vs class to override bootstrap styles on the links */
.navbar-expand {
    justify-content: flex-end !important;
}

.navbar-slide-in {
    top: 0;
    transition: top 500ms ease 0s;
}

.nav-item {
    font-size: 1.5rem;
}

#nav__link {
    opacity: .8;
}

#nav__link-home , #nav__link:hover, #nav__link.active  {
    color: rgb(116, 102, 52);
    opacity: 1;
}
