@import url(https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap);
/* Note-- had to use ID's vs class to override bootstrap styles on the links */
.navbar-expand {
    justify-content: flex-end !important;
}

.navbar-slide-in {
    top: 0;
    transition: top 500ms ease 0s;
}

.nav-item {
    font-size: 1.5rem;
}

#nav__link {
    opacity: .8;
}

#nav__link-home , #nav__link:hover, #nav__link.active  {
    color: rgb(116, 102, 52);
    opacity: 1;
}

.footer {
    display: block;
    text-align: center;
    font-size: 0.75em;
    bottom:0;
    width: 100%;
    height: 30px;
    position: fixed;
}

.footer a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
 }
.wrapper {
  font-family: 'Cutive Mono', monospace;
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper__img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 1024px;
  z-index: -10;
}

@media (max-width: 1024px) { 
  .wrapper__img {
    left: 50%;
    margin-left: -512px;   /* 50% */
  }
}

.loader {
    margin: 0 auto;
}
/* Global CSS */
html, body {
    overflow-x: hidden;
    overscroll-behavior-y: none;
}
