.footer {
    display: block;
    text-align: center;
    font-size: 0.75em;
    bottom:0;
    width: 100%;
    height: 30px;
    position: fixed;
}

.footer a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
 }